
import OfferCard from '@/components/offer_card'
import PageNavbar from '@/components/page_navbar'
import LoadingSpinner from '@/components/loading_spinner'
import _ from 'underscore'

export default {
  components: {
    OfferCard,
    PageNavbar,
    LoadingSpinner
  }

  computed: {
    loading: ->
      @$store.state.offers.loading

    offers: ->
      @$store.state.offers.all

    categorized: ->
      grouped = _.groupBy(@offers, 'root_category_name')
      categories = {}
      Object.keys(grouped).sort().forEach (category) ->
        data = grouped[category]
        categories[category] = _.chain(data).sortBy (offer) ->
          offer.sellable.brand
        .sortBy (offer) ->
          offer.sellable.correlations?[0]?.name
        .sortBy (offer) ->
          offer.sellable.product
        .value()
      categories
  }

  created: ->
    @$store.dispatch('offers/fetch_supplier_stokvel')

  destroyed: ->
    @$store.commit('offers/reset')

}
